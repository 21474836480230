<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>VPS Starter</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[1]"/>
        <h2>VPS Starter</h2>
        <p>Het pakket met 50GB dataverkeer per maand voor virtual server hosting vormt een goede professionele keuze. Dankzij 2GB RAM en 25GB opslag kunt u rekenen op een uitstekend pakket voor professionele mogelijkheden. Host op de virtuele serverruimte meerdere websites en/of applicaties, afhankelijk van de benodigdheden voor uw organisatie. Wij zorgen er met de VPS voor dat u niet hoeft te investeren in een fysieke server, maar dat u wel de volledige vrijheid heeft om de ruimte te benutten zoals u wilt.</p>
        <h3>Virtual server hosting</h3>
        <p>Virtual server hosting biedt een aantal belangrijke voordelen. U heeft hiermee de volledige vrijheid, bijvoorbeeld om een besturingssysteem naar keuze te installeren, Gebruik maken van Linux VPS of een ander besturingssysteem? U bepaalt zelf hoe u er gebruik van wilt maken.</p>
        <p>Dankzij 2 CPU cores en 2GB RAM kunt u rekenen op een professionele VPS op basis van een uitstekend pakket. Zorg er met dit pakket voor virtual server hosting voor dat u zelf geen fysieke server hoeft aan te schaffen en dat u toch gebruik kunt maken van een virtueel afgebakend deel van onze servers.</p>
        <ul>
          <li>Vrijheid van een virtuele server</li>
          <li>50GB dataverkeer voor al uw websites of applicaties</li>
          <li>25GB HDD opslagruimte</li>
          <li>Kies zelf het besturingssysteem</li>
        </ul>
        <h3>Kies zelf het besturingssysteem (OS)</h3>
        <p>Dankzij virtual server hosting hoeft u niet te investeren in een eigen fysieke server, maar heeft u wel eigen virtuele serverruimte tot uw beschikking. Dat betekent bijvoorbeeld dat u zelf het besturingssysteem (OS) kunt kiezen dat u wenst te gebruiken, er gelden op dit gebied geen restricties. U kunt de ruimte gebruiken zoals u wenst, waardoor u daarin de volledige vrijheid heeft.</p>
        <p><i>“De ideale oplossing voor onze virtual server hosting. Voldoende serverruimte, razendsnel dankzij 2 CPU cores en de vrijheid om Linux te installeren. Een pakket dat precies aansluit bij de wensen van onze organisatie”</i>, aldus een van onze tevreden klanten.</p>
        <h3>VPS hosting met 2 CPU cores</h3>
        <p>Met dit pakket voor virtual server hosting kiest u voor 2 CPU cores, 2GB RAM, 25GB opslagruimte en 50GB dataverkeer per maand. Dat betekent een professionele keuze voor VPS hosting, waar u binnen uw organisatie op verschillende manieren gebruik van kunt maken. Direct online te bestellen.</p>
      </div>
      <CloudServersRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import CloudServersRightSideBlocks from '@/components/CloudServersRightSideBlocks.vue';

export default {
  name: 'VPSStarter',
  components: {
    Breadcrumbs,
    Price,
    CloudServersRightSideBlocks,
  },
  setup() {
    const data = inject('data');

    return {
      // static data
      data,
    };
  },
}
</script>